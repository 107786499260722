<template>
  <div class="l-wrapper">
    <AppHeader />
    <main class="l-main -login">
      <div class="l-lead">
        <h1 class="c-lead">パスワードをお忘れの方</h1>
      </div>
      <div class="l-contents-white l-box">
        <p class="l-box">
          メールに記載のURLからパスワードの再発行ができます。<br />
          メールが届かない方は受信設定を確認してください。
        </p>
        <router-link to="/reminder/input" class="c-btn secondary small"
          >メール再送信</router-link
        >
      </div>
      <!-- /.l-contents-white -->
      <div class="l-btns">
        <router-link to="/login" class="c-btn secondary mid"
          >ログインへ</router-link
        >
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
export default {
  name: "password-reminder-comp",
  mounted() {
    // public系の画面はログアウトさせる
    this.$store.commit("auth/set", false);
    this.$store.commit("userInfo/set", {});
  },
};
</script>
